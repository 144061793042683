<template>
    <div
      class="uk-flex-center uk-flex height-100"
      uk-grid
    >
      <router-view />
    </div>
  </template>
  
  <style scoped>
    .height-100{
        height: 100%;
    }
  </style>
  