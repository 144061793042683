import { createRouter, createWebHistory } from 'vue-router'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import LoginPage from '@/views/auth/LoginPage.vue'
import MainLayout from '@/components/layouts/MainLayout.vue' // Import MainLayout
import NotFoundPage from '@/components/globals/NotFoundPage.vue' // Import NotFoundPage
import { dynamicMenuItems } from '@/components/sidebar/SidebarMenu.vue' // Import dynamicMenuItems
import store from '@/store';

// Static routes
const routes = [
    {
        path: '/',
        component: AuthLayout,
        redirect: '/login',
        children: [
            {
                path: '/login',
                name: 'Login',
                component: LoginPage,
                meta: { 
                    title: 'Login',
                    public: true,
                    onlyWhenLogout: true
                }
            },
            {
                path: '/forgot-password',
                name: 'ForgotPassword',
                component: () => import(/* webpackChunkName: "forgot" */ '@/views/auth/ForgotPage.vue'),
                meta: { 
                    title: 'Lupa Password',
                    public: true,
                    onlyWhenLogout: true
                }
            },
            {
                path: '/change-password/:token',
                name: 'ChangePassword',
                component: () => import(/* webpackChunkName: "forgot" */ '@/views/auth/ChangePasswordPage.vue'),
                meta: { 
                    title: 'Password Baru',
                    public: true,
                    onlyWhenLogout: true
                }
            },
            {
                path: '/logout',
                name: 'Logout',
                meta: { requiresAuth: true },
                beforeEnter: (to, from, next) => {
                    store.dispatch('auth/logout').then(() => {
                        next({ path: '/login' });
                    });
                }
            }
        ]
    }
]

// Add routes dynamically from dynamicMenuItems
dynamicMenuItems.forEach(menu => {
    if (menu.children) {
        // For categories with nested routes
        menu.children.forEach(subMenu => {
            const baseRoutePath = `/dashboard${menu.path}${subMenu.path}`;
            const baseRouteName = subMenu.title.replace(/[^a-zA-Z0-9]/g, '');

            const childRoutes = [];

            childRoutes.push({
                path: '',
                name: baseRouteName + 'Page',
                component: async () => {
                    try {
                        return await import(`@/views/pages${baseRoutePath}/${baseRouteName}Page.vue`);
                    } catch (error) {
                        return NotFoundPage;
                    }
                },
                meta: { title: `${subMenu.title}`, requiresAuth: true }
            });
            // Check for 'read' permission to add detail page
            if (subMenu.permission.read) {
                childRoutes.push({
                    path: 'detail/:id',
                    name: baseRouteName + 'Detail',
                    component: async () => {
                        try {
                            return await import(`@/views/pages${baseRoutePath}/detail/Detail${baseRouteName}Page.vue`);
                        } catch (error) {
                            return NotFoundPage;
                        }
                    },
                    meta: { title: `Detail ${subMenu.title}`, requiresAuth: true }
                });
            }

            // Check for 'create' permission to add create page
            if (subMenu.permission.create) {
                childRoutes.push({
                    path: 'add',
                    name: baseRouteName + 'Add',
                    component: async () => {
                        try {
                            return await import(`@/views/pages${baseRoutePath}/add/Add${baseRouteName}Page.vue`);
                        } catch (error) {
                            return NotFoundPage;
                        }
                    },
                    meta: { title: `Add ${subMenu.title}`, requiresAuth: true }
                });
            }

            // Check for 'update' permission to add edit page
            if (subMenu.permission.update) {
                childRoutes.push({
                    path: 'edit/:id',
                    name: baseRouteName + 'Edit',
                    component: async () => {
                        try {
                            return await import(`@/views/pages${baseRoutePath}/edit/Edit${baseRouteName}Page.vue`);
                        } catch (error) {
                            return NotFoundPage;
                        }
                    },
                    meta: { title: `Edit ${subMenu.title}`, requiresAuth: true }
                });
            }

            // Check for 'delete' permission to add delete page
            if (subMenu.permission.delete) {
                childRoutes.push({
                    path: 'delete/:id',
                    name: baseRouteName + 'Delete',
                    component: async () => {
                        try {
                            return await import(`@/views/pages${baseRoutePath}/delete/Delete${baseRouteName}Page.vue`);
                        } catch (error) {
                            return NotFoundPage;
                        }
                    },
                    meta: { title: `Delete ${subMenu.title}`, requiresAuth: true }
                });
            }

            // Check for 'approve' permission to add approve page
            if (subMenu.permission.approve) {
                childRoutes.push({
                    path: 'approve/:id',
                    name: baseRouteName + 'Approve',
                    component: async () => {
                        try {
                            return await import(`@/views/pages${baseRoutePath}/approve/Approve${baseRouteName}Page.vue`);
                        } catch (error) {
                            return NotFoundPage;
                        }
                    },
                    meta: { title: `Approve ${subMenu.title}`, requiresAuth: true }
                });
            }

            // Check for 'upload' permission to add upload page
            if (subMenu.permission.upload) {
                childRoutes.push({
                    path: 'upload',
                    name: baseRouteName + 'Upload',
                    component: async () => {
                        try {
                            return await import(`@/views/pages${baseRoutePath}/upload/Upload${baseRouteName}Page.vue`);
                        } catch (error) {
                            return NotFoundPage;
                        }
                    },
                    meta: { title: `Upload ${subMenu.title}`, requiresAuth: true }
                });
            }

            if (childRoutes.length > 0) {
                routes.push({
                    path: subMenu.path,
                    component: MainLayout,
                    children: childRoutes,
                    meta: {title: subMenu.title}
                });
            }
        });
    } else {
        // For categories without nested routes
        const baseRoutePath = `/dashboard${menu.path}`;
        const baseRouteName = menu.title.replace(/[^a-zA-Z0-9]/g, '');

        const childRoutes = [];

        childRoutes.push({
            path: '',
            name: baseRouteName + 'Page',
            component: async () => {
                try {
                    return await import(`@/views/pages${baseRoutePath}/${baseRouteName}Page.vue`);
                } catch (error) {
                    return NotFoundPage;
                }
            },
            meta: { title: menu.title , requiresAuth: true}
        });
        // Similar permission checks for the top-level menu

        if (menu.permission.create) {
            childRoutes.push({
                path: 'add',
                name: baseRouteName + 'Add',
                component: async () => {
                    try {
                        return await import(`@/views/pages${baseRoutePath}/add/Add${baseRouteName}Page.vue`);
                    } catch (error) {
                        return NotFoundPage;
                    }
                },
                meta: { title: `Add ${menu.title}`, requiresAuth: true }
            });
        }

        if (menu.permission.read) {
            childRoutes.push({
                path: 'detail/:id',
                name: baseRouteName + 'Detail',
                component: async () => {
                    try {
                        return await import(`@/views/pages${baseRoutePath}/detail/Detail${baseRouteName}Page.vue`);
                    } catch (error) {
                        return NotFoundPage;
                    }
                },
                meta: { title: menu.title, requiresAuth: true }
            });
        }

        if (menu.permission.update) {
            childRoutes.push({
                path: 'edit/:id',
                name: baseRouteName + 'Edit',
                component: async () => {
                    try {
                        return await import(`@/views/pages${baseRoutePath}/edit/Edit${baseRouteName}Page.vue`);
                    } catch (error) {
                        return NotFoundPage;
                    }
                },
                meta: { title: `Edit ${menu.title}`, requiresAuth: true }
            });
        }

        if (menu.permission.delete) {
            childRoutes.push({
                path: 'delete/:id',
                name: baseRouteName + 'Delete',
                component: async () => {
                    try {
                        return await import(`@/views/pages${baseRoutePath}/delete/Delete${baseRouteName}Page.vue`);
                    } catch (error) {
                        return NotFoundPage;
                    }
                },
                meta: { title: `Delete ${menu.title}`, requiresAuth: true }
            });
        }

        if (menu.permission.approve) {
            childRoutes.push({
                path: 'approve/:id',
                name: baseRouteName + 'Approve',
                component: async () => {
                    try {
                        return await import(`@/views/pages${baseRoutePath}/approve/Approve${baseRouteName}Page.vue`);
                    } catch (error) {
                        return NotFoundPage;
                    }
                },
                meta: { title: `Approve ${menu.title}`, requiresAuth: true }
            });
        }

        if (menu.permission.upload) {
            childRoutes.push({
                path: 'upload',
                name: baseRouteName + 'Upload',
                component: async () => {
                    try {
                        return await import(`@/views/pages${baseRoutePath}/upload/Upload${baseRouteName}Page.vue`);
                    } catch (error) {
                        return NotFoundPage;
                    }
                },
                meta: { title: `Upload ${menu.title}`, requiresAuth: true }
            });
        }
        
        if (childRoutes.length > 0) {
            routes.push({
                path: menu.path,
                component: MainLayout,
                children: childRoutes,
                meta: {title: menu.title}
            });
        }
    }
});


// 404 Page Not Found route
routes.push({
    path: '/:catchAll(.*)',
    component: MainLayout,
    children: [
        {
            path: '',
            name: 'NotFound',
            component: NotFoundPage,
            meta: { title: 'Page Not Found' }
        }
    ]
})

const router = createRouter({
    history: createWebHistory(),
    routes
})

// Navigation guard to handle authentication
router.beforeEach((to, from, next) => {
    const isAuthenticated = store.state.auth.isAuthenticated; // Get auth status from Vuex store
    console.log(isAuthenticated);
    
    // Check if the route requires authentication
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            console.log('not auth');
            
            // User is not authenticated, redirect to login
            next({ path: '/login', query: { redirect: to.fullPath } });
        } else {
            next(); // Proceed to route
        }
    } 
    // Check if the route should only be accessible when logged out
    else if (to.matched.some(record => record.meta.onlyWhenLogout)) {
        if (isAuthenticated) {
            console.log('auth');
            
            next({ path: '/user' }); // Redirect to home/dashboard if authenticated
        } else {
            next(); // Proceed to route if not authenticated
        }
    } 
    // Check if the route is public or doesn't require authentication
    else if (to.matched.some(record => record.meta.public) || 
             ['/login', '/forgot-password'].includes(to.path)) {
        next(); // Allow access to public routes or login/forgot password routes
    } 
    // Handle unknown routes (should ideally redirect to a 404 page)
    else {
        next({ path: '/login' }); // Redirect to login if the route does not match any criteria
    }
});
  
export default router;
