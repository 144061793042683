import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'; 
import { API_AUTH } from '@/utils/api-url'; 

const state = { 
  roles: [], 
  role: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_ROLES(state, roles) { 
    state.roles = roles; 
  }, 
  SET_ROLE(state, role) { 
    state.role = role; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getRole({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.ROLE}?limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_ROLES', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createRole({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuth(API_AUTH.CREATE_ROLE, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateRole({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPutAuth(API_AUTH.UPDATE_ROLE(payload.id), payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getRoleById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.ROLE}/${id}`); 
      if (response.data.status_code === 200) { 
        commit('SET_ROLE', response.data.data); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getModule({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.MODULE}`); 
      if (response.data.status_code === 200) {
        // Return the mapped module data
        return response.data.data.map(module => ({
          id: module.id,
          name: module.module_name // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getParent({ commit }, { module_id }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.PARENT}?module_id=${module_id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data.map(parent => ({
          id: parent.id,
          name: parent.module_name // Adjust to use module_name for parents
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }

}; 

const getters = { 
  getRoles(state) { 
    return state.roles; 
  }, 
  getRole(state) { 
    return state.role; 
  }, 
  getTotalRoles(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
