const BASE_URL_AUTH = `${process.env.VUE_APP_AUTH_SERVICE_URL}/v1/cms`;

// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL_AUTH}/authorization/login`,
  LOGOUT: `${BASE_URL_AUTH}/authorization/logout`,
  FORGOT_PASSWORD: `${BASE_URL_AUTH}/authorization/forgot-password`,
  MENU: `${BASE_URL_AUTH}/master/menu`,
  CREATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  UPDATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  DETAIL_MENU: id => `${BASE_URL_AUTH}/master/menu/${id}`,
  PARENT: `${BASE_URL_AUTH}/master/menu/parent`,
  USER: `${BASE_URL_AUTH}/master/user`,
  CREATE_USER: `${BASE_URL_AUTH}/master/user`,
  UPDATE_USER: (id) =>`${BASE_URL_AUTH}/master/user/${id}`,
  DETAIL_USER: id => `${BASE_URL_AUTH}/master/user/${id}`,
  DEPARTMENT: `${BASE_URL_AUTH}/master/user/departments`,
  REGION: `${BASE_URL_AUTH}/master/user/regions`,
  SUB_REGION: `${BASE_URL_AUTH}/master/user/sub-regions`,
  BRANCHES: `${BASE_URL_AUTH}/master/user/branches`,
  ROLES: `${BASE_URL_AUTH}/master/user/roles`,
  ROLE: `${BASE_URL_AUTH}/master/role`,
  CREATE_ROLE: `${BASE_URL_AUTH}/master/role`,
  UPDATE_ROLE: (id)=>`${BASE_URL_AUTH}/master/role/${id}`,
  DETAIL_ROLE: id => `${BASE_URL_AUTH}/master/role/${id}`,
  MODULE: `${BASE_URL_AUTH}/master/module`
};