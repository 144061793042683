<template>
    <div class="wrapper">
      <NavbarMenu />
      <SidebarMenu />
      <div class="content-wrapper">
        <div class="content">
          <router-view />
        </div>
      </div>
    </div>
</template>
  
<script>
import SidebarMenu from '@/components/sidebar/SidebarMenu.vue';
import NavbarMenu from '@/components/navbar/NavbarMenu.vue';
  
export default {
    components: {
        SidebarMenu,
        NavbarMenu
    },
    computed: {
        title() {
            return this.$route.meta.title || 'Default Title';
        }
    }
}
</script>
  
<style scoped>
  .main-layout {
    display: flex;
  }
  .container {
    background-color: #F5F7FA;
  }
  
  .header {
    padding: 24px;
    background-color: #fff;
    border-bottom: 1px solid #E9ECEF;
  }

  .header h1 {
    color: #495057;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
  }

  .content-wrapper>.content {
    flex: 1;
    padding: 20px;
    background-color: #F5F7FA;
  }

  .main-content {
    overflow: auto !important;
  }
</style>
