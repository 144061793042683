<template>
    <div class="not-found">
      <h1>Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  </template>
  
  <script>
  export default {
    metaInfo: {
      title: 'Page Not Found'
    }
  };
  </script>
  
  <style scoped>
  .not-found {
    padding: 20px;
    text-align: center;
  }
  </style>
  