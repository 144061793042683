<template>
  <aside class="main-sidebar sidebar-light-primary">
    <!-- Brand Logo -->
    <img src="@/assets/logo/logo.png" alt="AdminLTE Logo" class="brand-image" style="opacity: .8">

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li v-for="menu in computedMenus" :key="menu.title" class="nav-item" :class="{ 'menu-open': menu.open }">
            <a 
              href="#" 
              class="nav-link" 
              :class="{ active: $route.path === menu.path }"
            >
              <i :class="menu.icon"></i>
              <p>
                {{ menu.title }}
                <i v-if="menu.children" class="right fas fa-angle-left"></i>
              </p>
            </a>
            <!-- Child Menus -->
            <ul v-if="menu.children" class="nav nav-treeview">
              <li v-for="child in menu.children" :key="child.path" class="nav-item">
                <router-link 
                  :to="child.path" 
                  class="nav-link"
                  :class="{ active: $route.path.startsWith(child.path) }"
                >
                  <p>{{ child.title }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>
  
<script>
import { mapGetters } from 'vuex';

export const dynamicMenuItems = [
  { 
    title: "Data Master", 
    path: "/data-master", 
    icon: "nav-icon fa fa-database", 
    children: [
      { title: "User", path: "/user", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
      },
      { title: "Role", path: "/role", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
      },
      { title: "Menu", path: "/menu", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
      },
      { title: "Outlet", path: "/outlet", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
      },
      { title: "Events", path: "/events", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
      },
    ],
    open: true
  },
  { title: "Program & Promo", path: "/program-promo", icon: "nav-icon fa fa-star", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
  },
  { title: "Event Management", path: "/event-management", icon: "nav-icon fa fa-calendar", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
  },
  { title: "Mission Gamification", path: "/mission-gamification", icon: "nav-icon fa fa-trophy", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
  },
  { title: "Approval", path: "/approval", icon: "nav-icon fa fa-circle", permission: {
          "create": true,
          "read": true,
          "update": true,
          "delete": true,
          "approve": true,
          "upload": true
        }
  },
];

export default {
  data() {
    return {
      dynamicMenus: dynamicMenuItems
    };
  },
  computed: {
    ...mapGetters('auth', {
      isAuthenticated: 'isAuthenticated',  // maps to auth.isAuthenticated
      userProfile: 'getUserProfile',       // maps to auth.getUserProfile
      userRole: 'getUserRole',             // maps to auth.getUserRole
      userMenu: 'getUserMenu'              // maps to auth.getUserMenu
    }),
    computedMenus() {
      return this.dynamicMenus;
    }
  },
  watch: {
    '$route.path': function() {
      // When route changes, ensure the correct menu stays open
      this.dynamicMenus.forEach(menu => {
        if (menu.children) {
          menu.open = menu.children.some(child => this.$route.path.startsWith(child.path));
        }
      });
    }
  },
  mounted() {
    // Initialize menu state based on the current route
    this.dynamicMenus.forEach(menu => {
      if (menu.children) {
        menu.open = menu.children.some(child => this.$route.path.startsWith(child.path));
      }
    });
  }
};

</script>


<style>
.nav-treeview .nav-item .nav-link {
  padding-left: 47px;
}
.nav-sidebar .nav-link.active {
  background-color: #CCE1FA !important;
}
</style>
  