import Cookies from 'js-cookie'
import pako from 'pako'
import router from '@/router'

export const setCookie = (token) => {
    // Add the 'Bearer' prefix to the token
    const tokenWithBearer = `Bearer ${token}`;
  
    // Compress the token with gzip
    const compressedData = pako.gzip(tokenWithBearer, { to: 'string' });
  
    // Encode compressed data to base64
    const encodedData = btoa(String.fromCharCode.apply(null, compressedData));
  
    // Set cookie with compressed and encoded data
    Cookies.set('orbit-token', encodedData, { path: '/' });
  
    return {
      token: Cookies.get('orbit-token')
    };
};
  

export const decodeAndDecompressCookie = (cookieName) => {
  try {
    // Retrieve encoded data from cookie
    const encodedData = Cookies.get(cookieName)
    if (!encodedData) {
      console.error(`Cookie ${cookieName} not found.`)
      return
    }

    // Decode base64 encoded data
    const decodedData = atob(encodedData)

    // Convert decoded string back to Uint8Array
    const byteArray = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i)
    }

    // Decompress gzip compressed data using pako
    const decompressedData = pako.inflate(byteArray, { to: 'string' })

    return decompressedData
  } catch (error) {
    console.error('Error decoding and decompressing cookie:', error)
    return null
  }
}

export const setCookieProfile = (cookie) => {
  Cookies.set('orbit-profile', cookie, { path: '/' })
  return {
    profile: Cookies.get('orbit-profile')
  }
}

export const getUserToken = () => {
    const decodedToken = decodeAndDecompressCookie('orbit-token');
  
    if (!decodedToken) {
      return null;
    }
  
    // Ensure the token is returned with "Bearer" if it's not already included
    if (!decodedToken.startsWith('Bearer ')) {
      return `Bearer ${decodedToken}`;
    }
  
    return decodedToken;
};  

export const getUserProfile = () => {
    const profile = Cookies.get('orbit-profile');
    if (!profile) {
      console.error('Profile cookie not found or is undefined.');
      return null;
    }
    try {
      return JSON.parse(profile);
    } catch (error) {
      console.error('Error parsing profile cookie:', error);
      return null;
    }
};

export const isLogin = () => {
  const token = Cookies.get('orbit-token')
  const profile = Cookies.get('orbit-profile')
  if (token && profile) {
    return true
  } 
  return false
}

export const isAuthorizePage = (url) => {
  if(getUserProfile()?.role == 'Super Admin') {
    return true
  }
  if (!localStorage.getItem('all_menu')) {
    return true
  }
  const roles = JSON.parse(localStorage.getItem('all_menu')).some(role=> role.to == url)
  return roles
} 

export const logoutUser = () => {
  Cookies.remove('orbit-token', { path: '/' })
  Cookies.remove('orbit-profile', { path: '/' })
  localStorage.removeItem('role_managements')
  localStorage.removeItem('all_menu')
  router.go(0)
}

export function handleLogout(response) {
  if (response.data.status_code === 401 && response.data.message === 'account_already_logout') {
    logoutUser();
    router.push({ path: '/login' });
  }
}

export const getDataRole = () => {
  return JSON.parse(localStorage.getItem('role_managements'))
}

