import axios from 'axios';
import { METHOD } from './constant';
import { getUserToken, handleLogout } from '@/utils/auth';

// Create an Axios instance
const apiInstance = axios.create();

// Add a response interceptor
apiInstance.interceptors.response.use(
  function (response) {
    if (response?.status === 401 && response?.data?.message === 'account_already_logout') {
      handleLogout(response);
    }
    return response;
  },
  function (error) {
    if (error?.response?.status === 401 && error?.response?.data?.message === 'account_already_logout') {
      handleLogout(error.response);
    }

    return Promise.reject(error);
  }
);

export const apiGetNonAuth = (URL, params) => axios({
  url: URL,
  method: METHOD.GET,
  params
});

export const apiPostNonAuth = (URL, data) => axios({
  url: URL,
  method: METHOD.POST,
  data
});

export const apiGetAuth = (URL, params) => apiInstance({
  url: URL,
  method: METHOD.GET,
  params,
  headers: {
    Authorization: getUserToken()
  }
});

export const apiGetAuthWithCustomHeadersResponseType = (URL, params) => apiInstance({
  url: URL,
  method: METHOD.GET,
  params,
  headers: {
    "Content-Type": "application/octet-stream",
    Authorization: getUserToken()
  },
  responseType: 'arraybuffer'
});

export const apiPostAuth = (URL, data) => apiInstance({
  url: URL,
  method: METHOD.POST,
  data,
  headers: {
    Authorization: getUserToken()
  }
});

export const apiPostAuthWithResponseType = (URL, responseType, data) => apiInstance({
  url: URL,
  method: METHOD.POST,
  data,
  headers: {
    Authorization: getUserToken()
  },
  responseType
});

export const apiPutAuth = (URL, data) => apiInstance({
  url: URL,
  method: METHOD.PUT,
  data,
  headers: {
    Authorization: getUserToken()
  }
});

export const apiPatchAuth = (URL, data) => apiInstance({
  url: URL,
  method: METHOD.PATCH,
  data,
  headers: {
    Authorization: getUserToken()
  }
});

export const apiDeleteAuth = (URL, data) => axios({
  url: URL,
  method: METHOD.DELETE,
  data,
  headers: {
    Authorization: getUserToken()
  }
});
