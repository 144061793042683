<template>
  <router-view />
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
/* Global styles */
html, body, #app {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

* {
  box-sizing: border-box;
}
</style>
