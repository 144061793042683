import { createStore } from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import menu from './modules/menu';
import role from './modules/role';

export default createStore({
  modules: {
    auth,
    user,
    menu,
    role
  }
});
